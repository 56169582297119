export const EVENTS_LIST = "shared.events-list";
export const FILES_LIST = "shared.files-list";
export const HERO = "shared.hero";
export const NEWSLETTER_FORM = "shared.newsletter";
export const PEOPLE = "shared.people";
export const POSTS_LIST = "shared.posts-list";
export const ROADMAP = "shared.roadmap";
export const TEXT = "shared.text";
export const TEXT_COLUMNS = "shared.text-columns";
export const TEXT_IMAGE = "shared.text-image";
export const TITLE_WITH_LIST_ITEMS = "shared.title-list-items";
export const TITLE_SUBTITLE_ITEMS_LIST = "shared.title-subtitle-items-list";
export const TITLE_IMAGES_LIST = "shared.title-images-list";

export const componentSlugs = [
  EVENTS_LIST,
  FILES_LIST,
  HERO,
  NEWSLETTER_FORM,
  PEOPLE,
  POSTS_LIST,
  ROADMAP,
  TEXT,
  TEXT_COLUMNS,
  TEXT_IMAGE,
  TITLE_IMAGES_LIST,
  TITLE_SUBTITLE_ITEMS_LIST,
  TITLE_WITH_LIST_ITEMS,
] as const;

export type ComponentSlugs = (typeof componentSlugs)[number];
