<template>
  <component
    :is="componentsMap[component.__component]"
    v-for="component in data?.attributes.components"
    :key="`${component.__component}-${component.id}-${data.attributes.slug}`"
    :data="component"
  />
</template>

<script lang="ts" setup>
import { useSeoData } from "~/modules/shared/composables/useSeoData";
import type { Page } from "~/modules/shared/ts/Page";
import {
  type ComponentSlugs,
  EVENTS_LIST,
  FILES_LIST,
  HERO,
  NEWSLETTER_FORM,
  PEOPLE,
  POSTS_LIST,
  ROADMAP,
  TEXT,
  TEXT_COLUMNS,
  TEXT_IMAGE,
  TITLE_IMAGES_LIST,
  TITLE_SUBTITLE_ITEMS_LIST,
  TITLE_WITH_LIST_ITEMS,
} from "~/modules/shared/ts/pageComponents/ComponentSlugs";

const props = defineProps<{
  data: Page;
}>();

const seoData = computed(() => {
  if (!props.data.attributes.seo) return {};

  return useSeoData(props.data.attributes.seo);
});

useHead(seoData);

const componentsMap: Record<ComponentSlugs, unknown> = {
  [EVENTS_LIST]: defineAsyncComponent(
    () => import("~/modules/events/containers/EventsList.vue"),
  ),
  [FILES_LIST]: defineAsyncComponent(
    () => import("~/modules/shared/components/filesList/FilesList.vue"),
  ),
  [HERO]: defineAsyncComponent(
    () => import("~/modules/shared/components/hero/HeroComponent.vue"),
  ),
  [NEWSLETTER_FORM]: defineAsyncComponent(
    () =>
      import("~/modules/shared/components/newsletterForm/NewsletterForm.vue"),
  ),
  [PEOPLE]: defineAsyncComponent(
    () => import("~/modules/shared/components/people/PeopleComponent.vue"),
  ),
  [POSTS_LIST]: defineAsyncComponent(
    () => import("~/modules/posts/containers/PostsList.vue"),
  ),
  [ROADMAP]: defineAsyncComponent(
    () => import("~/modules/shared/components/roadmap/RoadmapComponent.vue"),
  ),
  [TEXT]: defineAsyncComponent(
    () => import("~/modules/shared/components/textComponent/TextComponent.vue"),
  ),
  [TEXT_COLUMNS]: defineAsyncComponent(
    () => import("~/modules/shared/components/textColumns/TextColumns.vue"),
  ),
  [TEXT_IMAGE]: defineAsyncComponent(
    () => import("~/modules/shared/components/textImage/TextImage.vue"),
  ),
  [TITLE_IMAGES_LIST]: defineAsyncComponent(
    () =>
      import("~/modules/shared/components/titleImagesList/TitleImagesList.vue"),
  ),
  [TITLE_SUBTITLE_ITEMS_LIST]: defineAsyncComponent(
    () =>
      import(
        "~/modules/shared/components/titleSubtitleItemsList/TitleSubtitleItemsList.vue"
      ),
  ),
  [TITLE_WITH_LIST_ITEMS]: defineAsyncComponent(
    () =>
      import(
        "~/modules/shared/components/titleWithListItems/TitleWithListItems.vue"
      ),
  ),
};
</script>
